// NPM
import React from 'react'
import _ from 'lodash'

const Statistics = ({ section }) => {

  if(!section) {
    return null
  }

  const statistics = _.get(section, "statistics", []) 

  const renderStatistic = (statistic, statisticIndex) => {
    return (
      <div 
        className="Statistics__stat"
        key={ `Statistic-${ statisticIndex }` }>
          <div className="Statistics__stat-value">
            { statistic.value }
          </div>
          <div className="Statistics__stat-name">
            { statistic.name }
          </div>
      </div>
    )
  }

  return (
    <div className="Statistics">
      <h3 className="Statistics__title small-header">
        { section.title }
      </h3>

      <div className="Statistics__contents">
        { statistics.map(renderStatistic) }
      </div>
    </div>
  )

}

export default Statistics