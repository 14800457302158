// NPM
import React from 'react'

// Shared Components
import GenericProduct from 'src/components/pages/GenericProduct/GenericProduct'
import Timeline from 'src/components/Shared/Timeline'
import InfoColumns from 'src/components/Shared/InfoColumns'
import WorkTogether from 'src/components/Shared/WorkTogether'
import Statistics from 'src/components/Shared/Statistics'

// Components
import AboutDegreeHub from './AboutDegreeHub'

const DegreeHub = ({ sections }) => {
  const infoColumnsTitleSection = sections.find(s => s.uuid === "infoColumns_title")


  return (
    <div className="DegreeHub">
      <GenericProduct sections={ sections } />
      <AboutDegreeHub sections={ sections } />
      <Timeline section={ sections.find(s => s.uuid === "timeline") } />

      {
        infoColumnsTitleSection &&
          <h3 className="DegreeHub__infoColumnsTitle section-header small-header">
            { infoColumnsTitleSection.title }
          </h3>
      }

      <InfoColumns sections={ sections } />
      <Statistics section={ sections.find(s => s.uuid === "statistics") } />
      <WorkTogether section={ sections.find(s => s.uuid === "workTogether") } />
    </div>
  )

}

export default DegreeHub