// NPM
import React from 'react'
import _ from 'lodash'

// Shared Components
import Description from 'src/components/Shared/Description'

const AboutDegreeHub = ({ sections }) => {

  const titleSection = sections.find(s => s.uuid === "aboutDegreeHub_title")
  const contentSection = sections.find(s => s.uuid === "aboutDegreeHub_content")

  if(!titleSection) {
    return null
  }

  if(!contentSection) {
    return null
  }

  return (
    <div className="AboutDegreeHub">
      <div className="AboutDegreeHub__container">
        <div className="AboutDegreeHub__content">
          <h3 className="AboutDegreeHub__title small-header">
            { titleSection.title }
          </h3>

          <h4 className="AboutDegreeHub__subTitle">
            { contentSection.title }
          </h4>
          <Description
            className="AboutDegreeHub__description"
            description={ contentSection.description }
          />
        </div>

        <div className="AboutDegreeHub__image">
          <img 
            src={ _.get(contentSection, 'media.0.image.url') }  
            alt={ _.get(contentSection, 'media.0.imageAlt') }
          />
        </div>
      </div>
    </div>    
  )
}

export default AboutDegreeHub